import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Button,
  TextField,
  MenuItem,
  Link,
  CircularProgress,
  Snackbar
} from "@material-ui/core";
import { CONSTANTS } from "../../constants/app-constant";
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { resetAddUser } from "../../actions";
import { useDispatch } from "react-redux";
import "./login.scss";

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

const RegisterView = (props) => {
  const [open, setOpen] = useState(false);
  const [emailRegEx, setEmailRegEx] = useState(/^(?!.*(\+))\S+@\S+\.\S+/i);
  const { registerRequest, onRegisterSubmit } = props;
  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm({
    defaultValues: { roles: 'student' }
  });
  // const [role, setRole] = useState('student');
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();
  const token = query.get("token");

  useEffect(() => {
    // Allow + in local
    if (window.location.hostname !== 'qlearn.medlearnity.com') {
      setEmailRegEx(/\S+@\S+\.\S+/i);
    }
  }, []);

  useEffect(() => {
    if (registerRequest.errorMsg) {
      setTimeout(() => {
        dispatch(resetAddUser());
      }, 3000);
    } else if (registerRequest.addUser) {
      dispatch(resetAddUser());
      setOpen(true);
      reset();
      // setRole('student');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, history, registerRequest]);

  // const onRoleSelectChange = (value) => {
  //   setRole(value);
  // };

  const goToLogin = (e) => {
    e.stopPropagation();
    history.push("/login");
  };

  const registerUser = (data) => {
    if (data.email.includes('medlearnity.com')) {
      data.roles = 'tutor';
    } else {
      data.roles = 'student';
    }

    data.roles = [data.roles];

    if (token) {
      data.inviteToken = token;
    }

    onRegisterSubmit(data);
  };

  const handleClose = (event) => {
    setOpen(false);
  };

  return (
    <Box className="login-body">
      <Snackbar open={open} onClose={handleClose} autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Box className="reg-success-alert">
          Registered Successfully. A verification mail has been sent to your email. Please click on that link to verify account...
        </Box>
      </Snackbar>

      <Box component="div" className="app-name">
        QLearn
      </Box>

      <Box component="div" className="login-container">
        <Box component="div" className="error-container">
          {registerRequest.errorMsg}
        </Box>
        <form
          noValidate
          className="login-form"
          onSubmit={handleSubmit(registerUser)}
        >
          <TextField
            label="Name"
            {...register("name", { required: true })}
            error={errors.name?.type === "required"}
            helperText={
              errors.name?.type === "required"
                ? "Please enter your name"
                : undefined
            }
          />
          <TextField
            label="Email"
            {...register("email", { required: true, pattern: emailRegEx })}
            error={
              errors.email?.type === "required" ||
              errors.email?.type === "pattern"
            }
            helperText={
              errors.email?.type === "required"
                ? "Email is required"
                : errors.email?.type === "pattern"
                  ? "Please enter a valid email, it should not contain +"
                  : undefined
            }
            InputLabelProps={{
              shrink: watch('email') ? true : false
            }}
          />
          <TextField
            label="Password"
            type="password"
            {...register("password", {
              required: true,
              minLength: 8,
              pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()-+])[A-Za-z\d@.#$!%*?&]{8,15}$/
            })}
            error={
              errors.password?.type === "required" ||
              errors.password?.type === "minLength" ||
              errors.password?.type === "pattern"
            }
            helperText={
              errors.password?.type === "required"
                ? "Please enter a password"
                : errors.password?.type === "minLength"
                  ? "Password must be of 8 characters"
                  : errors.password?.type === "pattern"
                  ? "Password should contain 1 Uppercase, 1 Lowercase, 1 digit & 1 special character"
                  : undefined
            }
          />
          <TextField
            label="Confirm Password"
            type="password"
            {...register("confirmPassword", {
              required: true,
              validate: (value) => value === watch("password"),
            })}
            error={
              errors.confirmPassword?.type === "required" ||
              errors.confirmPassword?.type === "validate"
            }
            helperText={
              errors.confirmPassword?.type === "required"
                ? "Please enter a password"
                : errors.confirmPassword?.type === "validate"
                  ? "Password does not match"
                  : undefined
            }
            InputLabelProps={{
              shrink: watch('confirmPassword') ? true : false
            }}
          />
          {/* <TextField
            select
            label="Role"
            value={role}
            {...register("roles", { required: true })}
            error={errors.role?.type === "required"}
            disabled={!watch("email")?.includes('@medlearnity.com')}
          >
            {CONSTANTS.roles.map((option) => (
              <MenuItem key={option.value} value={option.value}
                selected={option.value === role}
                onClick={() => onRoleSelectChange(option.value)}>
                {option.label}
              </MenuItem>
            ))}
          </TextField> */}

          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="register-btn"
            disabled={registerRequest.loading}
          >
            Register
            {registerRequest.loading && (
              <CircularProgress size={24} className="loader" />
            )}
          </Button>
        </form>
        Already a user? &nbsp;
        <Link variant="body2" rel="noopener" onClick={goToLogin}>
          Sign In
        </Link>
      </Box>
    </Box>
  );
};

export default RegisterView;
