import { combineReducers } from "redux";
import errors from "./errReducer";
import {
    registerUser,
    login,
    userDetails,
    userDetailsFromToken,
    setUserActivity,
    getUserActivity,
    getUserPreference,
    setUserPreference,
    setFeedback,
    menuOpenStatus
} from "./user";
import {
    createQuiz,
    submitQuiz,
    getCategories,
    getQuesCategories,
    getQuiz,
    getQuizHistory,
    getQuestionHistory,
    getStudentQuizHistory,
    getOverallQuizHistory,
    getOverallStudentQuizHistory,
    getQuestionMetadata,
    reviewQuestion,
    getImage,
    getQuesFeedback,
    setQuesFeedback,
    setQuesCount,
    getQuesTutorFeedback,
    setQuesTutorFeedback,
    createTutorFeedbackBlock,
    endTutorFeedbackBlock
} from "./quiz";
import {
    createPaymentSession,
    updatePaymentStatus,
    getUserPackage,
    getUserPayments,
    cancelSubscription,
    selectPlan,
    getActivePlans
} from './payment';
import {
    getInvitations,
    sendInvitation,
    resendInvitation,
    getComment,
    setComment,
    getStudentFeedbackAll,
    getTutorFeedbackAll,
    getAllPlans,
    createPlan,
    updatePlan,
    deletePlan,
    sendInvites
} from './admin';

const rootReducer = combineReducers({
    errors,
    registerUser,
    loggedInUser: login,
    userDetailsFromToken: userDetailsFromToken,
    studentDetails: userDetails,
    createQuiz,
    quizDetails: getQuiz,
    submitQuiz,
    categoriesList: getCategories,
    quesCategoriesList: getQuesCategories,
    quizHistory: getQuizHistory,
    questionHistory: getQuestionHistory,
    studentQuizHistory: getStudentQuizHistory,
    overallQuizHistory: getOverallQuizHistory,
    overallStudentQuiz: getOverallStudentQuizHistory,
    questionMetadata: getQuestionMetadata,
    reviewQuestion,
    paymentSession: createPaymentSession,
    paymentStatus: updatePaymentStatus,
    userPackage: getUserPackage,
    setUserActivity,
    userActivity: getUserActivity,
    userPayments: getUserPayments,
    cancelSubscription,
    invitationsList: getInvitations,
    sendInvitation,
    resendInvitation,
    getUserPreference,
    setUserPreference,
    getComment,
    setComment,
    setFeedback,
    getImage,
    getQuesFeedback,
    setQuesFeedback,
    quesCount: setQuesCount,
    getQuesTutorFeedback,
    setQuesTutorFeedback,
    getStudentFeedbackAll,
    getTutorFeedbackAll,
    menuOpenStatus,
    createTutorFeedbackBlock,
    endTutorFeedbackBlock,
    getAllPlans,
    createPlan,
    updatePlan,
    deletePlan,
    selectPlan,
    getActivePlans,
    sendInvites
});

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) =>
    rootReducer(action.type === "LOGOUT" ? undefined : state, action);