import url from "../constants/urlList";
import { CONSTANTS } from "../constants/app-constant";
const errMsg = 'Something went wrong';

const doGetCall = (apiUrl) => {
    return fetch(apiUrl)
        .then(async (response) => {
            if (response.status === 200) {
                return response.json();
            }

            return {
                status: response.status,
                errMsg: await response?.json().then(res => res)
            };
        })
        .catch((error) => error);
};

const doUpdateCall = (apiUrl, data, isPutCall = false) => {
    const requestOptions = {
        method: isPutCall ? "PUT" : "POST",
        headers: { "Content-Type": "application/json" }
    };

    if (data !== undefined) {
        requestOptions.body = JSON.stringify(data);
    }

    return fetch(apiUrl, requestOptions)
        .then(async (response) => {
            if (response.status === 200 || response.status === 201) {
                return response.json();
            }

            return {
                errStatus: response.status,
                errMsg: await response?.json().then(res => res)
            };
        })
        .catch(() => {
            return {
                errStatus: "500",
                errMsg: CONSTANTS.errorMsgs[500],
            };
        });
};

const doDeleteCall = (apiUrl) => {
    const requestOptions = {
        method: "DELETE",
        headers: { "Content-Type": "application/json" }
    };

    return fetch(apiUrl, requestOptions)
        .then(async (response) => {
            if (response.status === 200) {
                return response.json();
            }

            return {
                errStatus: response.status,
                errMsg: await response?.json().then(res => res)
            };
        })
        .catch(() => {
            return {
                errStatus: "500",
                errMsg: CONSTANTS.errorMsgs[500],
            };
        });
};

export const api = {
    addUserApi(user) {
        return doUpdateCall(url.addUser, user);
    },
    doLogin(user) {
        return doUpdateCall(url.login, user);
    },
    getUserDetails(userId) {
        return doGetCall(url.userDetails + userId);
    },
    getUserDetailsFromToken() {
        return doGetCall(url.userDetailsFromToken);
    },
    createQuiz(quizData) {
        return doUpdateCall(url.createQuiz, quizData);
    },
    submitQuiz(quizData) {
        const quizUrl = `${url.submitQuiz}/${quizData.quizHistory.quizId}/user/${quizData.quizHistory.userId}/submit`;
        return doUpdateCall(quizUrl, quizData);
    },
    getCategories() {
        return doGetCall(url.categories);
    },
    getQuesCategories() {
        return doGetCall(url.questionCategories);
    },
    getQuestionHistory(quizId) {
        return doGetCall(url.questionHistory + quizId);
    },
    getQuiz(quizId) {
        return doGetCall(url.getQuiz + quizId);
    },
    getQuizHistory(quizId) {
        return doGetCall(url.quizHistory + quizId);
    },
    getStudentQuizHistory(quizId) {
        return doGetCall(url.studentQuizHistory + quizId);
    },
    getOverallQuizHistory(quizId) {
        return doGetCall(url.overallQuizHistory + quizId);
    },
    getOverallStudentQuiz(params) {
        const status = params.isCompleted ? "COMPLETE" : "IN_PROGRESS";
        return doGetCall(`${url.overallStudentQuiz}/${params.userId}?status=${status}`);
    },
    getQuestionMetadata(questionList) {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(questionList),
        };

        return fetch(url.questionMetadata, requestOptions)
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                }

                return {
                    status: response.status,
                    errMsg: errMsg
                };
            })
            .catch((error) => error);
    },
    reviewQuestion(param) {
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(param.reviewTime),
        };

        return fetch(
            `${url.reviewQuestion}/${param.questionId}/review`,
            requestOptions
        )
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                }

                return {
                    status: response.status,
                    errMsg: errMsg
                };
            })
            .catch((error) => error);
    },
    // getPackageList() {
    //     return doGetCall(url.packagePlan);
    // },
    getUserPackage(userid) {
        return doGetCall(`${url.userDetails}${userid}/plan/true`);
    },
    createPaymentSession(paymentData) {
        return doUpdateCall(url.paymentSession, paymentData);
    },
    updatePaymentStatus(param) {
        const statusUrl = param.isSuccess ? `${url.booking}/${param.bookingId}/status/SUCCESS` :
            `${url.booking}/${param.bookingId}/status/FAILED`;

        return doUpdateCall(statusUrl, undefined, true);
    },
    setUserActivity(paramData) {
        return doUpdateCall(url.userActivity + paramData.userId, paramData, true);
    },
    getUserActivity(userId) {
        return doGetCall(url.userActivity + userId);
    },
    getUserPayments(userId) {
        return doGetCall(url.userPayments + userId);
    },
    getInvitations() {
        return doGetCall(url.invitation + '?page=0&size=100');
    },
    cancelSubscription(bookingId) {
        return doUpdateCall(`${url.booking}/${bookingId}/${url.cancelSubscription}`, undefined);
    },
    sendInvitation(paramBody) {
        return doUpdateCall(url.paymentSession, paramBody);
    },
    resendInvitation(invitationId) {
        return doUpdateCall(`${url.invitation}/${invitationId}`, undefined, true);
    },
    getPreference(userId) {
        return doGetCall(`${url.preference}/${userId}`);
    },
    setPreference(param) {
        return doUpdateCall(`${url.preference}/${param.userId}`, param.body, true);
    },
    setFeedback(paramBody) {
        return doUpdateCall(url.paymentSession, paramBody);
    },
    setComment(param) {
        return doUpdateCall(`${url.comment}/${param.quesId}/comment`, param.commentParam);
    },
    getComment(param) {
        return doGetCall(`${url.comment}/${param.quesId}/comment`);
    },
    getImage(imageName) {
        return doGetCall(`${url.image}?imageName=${imageName}`);
    },
    getQuesFeedback(quesId) {
        return doGetCall(`${url.quesFeedback}/${quesId}`);
    },
    setQuesFeedback(paramBody) {
        return doUpdateCall(url.quesFeedback, paramBody);
    },
    getQuestionCount(paramBody) {
        return doUpdateCall(url.quesCatCount, paramBody);
    },
    getQuesTutorFeedback(quesId) {
        return doGetCall(`${url.quesTutorFeedback}/${quesId}`);
    },
    setQuesTutorFeedback(paramBody) {
        return doUpdateCall(url.quesTutorFeedback, paramBody);
    },
    getTutorFeedbackAll() {
        return doGetCall(url.tutorFeedbackAll);
    },
    getStudentFeedbackAll() {
        return doGetCall(url.studentFeedbackAll);
    },
    createTutorFeedbackBlock(block) {
        return doUpdateCall(url.feedbackBlock, block);
    },
    endTutorFeedbackBlock(blockId) {
        return doUpdateCall(`${url.feedbackBlock}/${blockId}/end`, undefined, true);
    },
    getAllPlans() {
        return doGetCall(url.packagePlan);
    },
    updatePlan(planData) {
        return doUpdateCall(`${url.packagePlan}/${planData.id}`, planData, true);
    },
    createPlan(planData) {
        return doUpdateCall(url.packagePlan, planData);
    },
    deletePlan(planId) {
        return doDeleteCall(`${url.packagePlan}/${planId}`);
    },
    selectPlan(body) {
        return doUpdateCall(`${url.userDetails}${body.userId}/plan/${body.planId}/select`, undefined, true);
    },
    getActivePlans(userid) {
        return doGetCall(`${url.userDetails}${userid}/plan?status=ACTIVE,CANCELLED`);
    },
    sendInvites(invitesParam) {
        return doUpdateCall(url.sendInvites, invitesParam);
    },
};