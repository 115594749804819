const types = {
    GET_ERRORS: "GET_ERRORS",
    ADD_USER_RESET: "ADD_USER_RESET",
    ADD_USER_REQUEST: "ADD_USER_REQUEST",
    ADD_USER_SUCCESS: "ADD_USER_SUCCESS",
    ADD_USER_ERROR: "ADD_USER_ERROR",
    LOGIN_USER_RESET: "LOGIN_USER_RESET",
    LOGIN_USER_REQUEST: "LOGIN_USER_REQUEST",
    LOGIN_USER_SUCCESS: "LOGIN_USER_SUCCESS",
    LOGIN_USER_ERROR: "LOGIN_USER_ERROR",
    USER_DETAILS_REQUEST: "USER_DETAILS_REQUEST",
    USER_DETAILS_SUCCESS: "USER_DETAILS_SUCCESS",
    USER_DETAILS_ERROR: "USER_DETAILS_ERROR",
    USER_DETAILS_FROM_TOKEN_REQUEST: "USER_DETAILS_FROM_TOKEN_REQUEST",
    USER_DETAILS_FROM_TOKEN_SUCCESS: "USER_DETAILS_FROM_TOKEN_SUCCESS",
    USER_DETAILS_FROM_TOKEN_ERROR: "USER_DETAILS_FROM_TOKEN_ERROR",
    GET_CATEGORIES_REQUEST: "GET_CATEGORIES_REQUEST",
    GET_CATEGORIES_SUCCESS: "GET_CATEGORIES_SUCCESS",
    GET_CATEGORIES_ERROR: "GET_CATEGORIES_ERROR",
    GET_QUES_CATEGORIES_REQUEST: "GET_QUES_CATEGORIES_REQUEST",
    GET_QUES_CATEGORIES_SUCCESS: "GET_QUES_CATEGORIES_SUCCESS",
    GET_QUES_CATEGORIES_ERROR: "GET_QUES_CATEGORIES_ERROR",
    CREATE_QUIZ_RESET: "CREATE_QUIZ_RESET",
    CREATE_QUIZ_REQUEST: "CREATE_QUIZ_REQUEST",
    CREATE_QUIZ_SUCCESS: "CREATE_QUIZ_SUCCESS",
    CREATE_QUIZ_ERROR: "CREATE_QUIZ_ERROR",
    SUBMIT_QUIZ_RESET: "SUBMIT_QUIZ_RESET",
    SUBMIT_QUIZ_REQUEST: "SUBMIT_QUIZ_REQUEST",
    SUBMIT_QUIZ_SUCCESS: "SUBMIT_QUIZ_SUCCESS",
    SUBMIT_QUIZ_ERROR: "SUBMIT_QUIZ_ERROR",
    GET_QUIZ_RESET: "GET_QUIZ_RESET",
    GET_QUIZ_REQUEST: "GET_QUIZ_REQUEST",
    GET_QUIZ_SUCCESS: "GET_QUIZ_SUCCESS",
    GET_QUIZ_ERROR: "GET_QUIZ_ERROR",
    GET_QUIZ_HISTORY_RESET: "GET_QUIZ_HISTORY_RESET",
    GET_QUIZ_HISTORY_REQUEST: "GET_QUIZ_HISTORY_REQUEST",
    GET_QUIZ_HISTORY_SUCCESS: "GET_QUIZ_HISTORY_SUCCESS",
    GET_QUIZ_HISTORY_ERROR: "GET_QUIZ_HISTORY_ERROR",
    GET_OVERALL_QUIZ_HISTORY_RESET: "GET_OVERALL_QUIZ_HISTORY_RESET",
    GET_OVERALL_QUIZ_HISTORY_REQUEST: "GET_OVERALL_QUIZ_HISTORY_REQUEST",
    GET_OVERALL_QUIZ_HISTORY_SUCCESS: "GET_OVERALL_QUIZ_HISTORY_SUCCESS",
    GET_OVERALL_QUIZ_HISTORY_ERROR: "GET_OVERALL_QUIZ_HISTORY_ERROR",
    GET_OVERALL_STUDENT_QUIZ_HISTORY_RESET: "GET_OVERALL_STUDENT_QUIZ_HISTORY_RESET",
    GET_OVERALL_STUDENT_QUIZ_HISTORY_REQUEST: "GET_OVERALL_STUDENT_QUIZ_HISTORY_REQUEST",
    GET_OVERALL_STUDENT_QUIZ_HISTORY_SUCCESS: "GET_OVERALL_STUDENT_QUIZ_HISTORY_SUCCESS",
    GET_OVERALL_STUDENT_QUIZ_HISTORY_ERROR: "GET_OVERALL_STUDENT_QUIZ_HISTORY_ERROR",
    GET_STUDENT_QUIZ_HISTORY_RESET: "GET_STUDENT_QUIZ_HISTORY_RESET",
    GET_STUDENT_QUIZ_HISTORY_REQUEST: "GET_STUDENT_QUIZ_HISTORY_REQUEST",
    GET_STUDENT_QUIZ_HISTORY_SUCCESS: "GET_STUDENT_QUIZ_HISTORY_SUCCESS",
    GET_STUDENT_QUIZ_HISTORY_ERROR: "GET_STUDENT_QUIZ_HISTORY_ERROR",
    GET_QUESTION_HISTORY_RESET: "GET_QUESTION_HISTORY_RESET",
    GET_QUESTION_HISTORY_REQUEST: "GET_QUESTION_HISTORY_REQUEST",
    GET_QUESTION_HISTORY_SUCCESS: "GET_QUESTION_HISTORY_SUCCESS",
    GET_QUESTION_HISTORY_ERROR: "GET_QUESTION_HISTORY_ERROR",
    GET_QUESTION_METADATA_RESET: "GET_QUESTION_METADATA_RESET",
    GET_QUESTION_METADATA_REQUEST: "GET_QUESTION_METADATA_REQUEST",
    GET_QUESTION_METADATA_SUCCESS: "GET_QUESTION_METADATA_SUCCESS",
    GET_QUESTION_METADATA_ERROR: "GET_QUESTION_METADATA_ERROR",
    GET_QUESTION_REVIEW_RESET: "GET_QUESTION_REVIEW_RESET",
    GET_QUESTION_REVIEW_REQUEST: "GET_QUESTION_REVIEW_REQUEST",
    GET_QUESTION_REVIEW_SUCCESS: "GET_QUESTION_REVIEW_SUCCESS",
    GET_QUESTION_REVIEW_ERROR: "GET_QUESTION_REVIEW_ERROR",
    GET_PACKAGE_REQUEST: "GET_PACKAGE_REQUEST",
    GET_PACKAGE_SUCCESS: "GET_PACKAGE_SUCCESS",
    GET_PACKAGE_ERROR: "GET_PACKAGE_ERROR",
    GET_USER_PACKAGE_REQUEST: "GET_USER_PACKAGE_REQUEST",
    GET_USER_PACKAGE_SUCCESS: "GET_USER_PACKAGE_SUCCESS",
    GET_USER_PACKAGE_ERROR: "GET_USER_PACKAGE_ERROR",
    RESET_PAYMENT_SESSION_REQUEST: "RESET_PAYMENT_SESSION_REQUEST",
    GET_PAYMENT_SESSION_REQUEST: "GET_PAYMENT_SESSION_REQUEST",
    GET_PAYMENT_SESSION_SUCCESS: "GET_PAYMENT_SESSION_SUCCESS",
    GET_PAYMENT_SESSION_ERROR: "GET_PAYMENT_SESSION_ERROR",
    GET_PAYMENT_STATUS_REQUEST: "GET_PAYMENT_STATUS_REQUEST",
    GET_PAYMENT_STATUS_SUCCESS: "GET_PAYMENT_STATUS_SUCCESS",
    GET_PAYMENT_STATUS_ERROR: "GET_PAYMENT_STATUS_ERROR",
    GET_USER_ACTIVITY_REQUEST: "GET_USER_ACTIVITY_REQUEST",
    GET_USER_ACTIVITY_SUCCESS: "GET_USER_ACTIVITY_SUCCESS",
    GET_USER_ACTIVITY_ERROR: "GET_USER_ACTIVITY_ERROR",
    SET_USER_ACTIVITY_REQUEST: "SET_USER_ACTIVITY_REQUEST",
    SET_USER_ACTIVITY_SUCCESS: "SET_USER_ACTIVITY_SUCCESS",
    SET_USER_ACTIVITY_ERROR: "SET_USER_ACTIVITY_ERROR",
    GET_USER_PAYMENT_REQUEST: "GET_USER_PAYMENT_REQUEST",
    GET_USER_PAYMENT_SUCCESS: "GET_USER_PAYMENT_SUCCESS",
    GET_USER_PAYMENT_ERROR: "GET_USER_PAYMENT_ERROR",
    CANCEL_SUBSCRIPTION_RESET: "CANCEL_SUBSCRIPTION_RESET",
    CANCEL_SUBSCRIPTION_REQUEST: "CANCEL_SUBSCRIPTION_REQUEST",
    CANCEL_SUBSCRIPTION_SUCCESS: "CANCEL_SUBSCRIPTION_SUCCESS",
    CANCEL_SUBSCRIPTION_ERROR: "CANCEL_SUBSCRIPTION_ERROR",
    SEND_INVITATION_RESET: "SEND_INVITATION_RESET",
    SEND_INVITATION_REQUEST: "SEND_INVITATION_REQUEST",
    SEND_INVITATION_SUCCESS: "SEND_INVITATION_SUCCESS",
    SEND_INVITATION_ERROR: "SEND_INVITATION_ERROR",
    RESEND_INVITATION_RESET: "RESEND_INVITATION_RESET",
    RESEND_INVITATION_REQUEST: "RESEND_INVITATION_REQUEST",
    RESEND_INVITATION_SUCCESS: "RESEND_INVITATION_SUCCESS",
    RESEND_INVITATION_ERROR: "RESEND_INVITATION_ERROR",
    GET_INVITATION_REQUEST: "GET_INVITATION_REQUEST",
    GET_INVITATION_SUCCESS: "GET_INVITATION_SUCCESS",
    GET_INVITATION_ERROR: "GET_INVITATION_ERROR",
    GET_PREFERENCE_REQUEST: "GET_PREFERENCE_REQUEST",
    GET_PREFERENCE_SUCCESS: "GET_PREFERENCE_SUCCESS",
    GET_PREFERENCE_ERROR: "GET_PREFERENCE_ERROR",
    SET_PREFERENCE_REQUEST: "SET_PREFERENCE_REQUEST",
    SET_PREFERENCE_SUCCESS: "SET_PREFERENCE_SUCCESS",
    SET_PREFERENCE_ERROR: "SET_PREFERENCE_ERROR",
    GET_IMAGE_REQUEST: "GET_IMAGE_REQUEST",
    GET_IMAGE_SUCCESS: "GET_IMAGE_SUCCESS",
    GET_IMAGE_ERROR: "GET_IMAGE_ERROR",
    GET_COMMENT_REQUEST: "GET_COMMENT_REQUEST",
    GET_COMMENT_SUCCESS: "GET_COMMENT_SUCCESS",
    GET_COMMENT_ERROR: "GET_COMMENT_ERROR",
    SET_COMMENT_REQUEST: "SET_COMMENT_REQUEST",
    SET_COMMENT_SUCCESS: "SET_COMMENT_SUCCESS",
    SET_COMMENT_ERROR: "SET_COMMENT_ERROR",
    SET_FEEDBACK_REQUEST: "SET_FEEDBACK_REQUEST",
    SET_FEEDBACK_SUCCESS: "SET_FEEDBACK_SUCCESS",
    SET_FEEDBACK_ERROR: "SET_FEEDBACK_ERROR",
    GET_QUES_FEEDBACK_REQUEST: "GET_QUES_FEEDBACK_REQUEST",
    GET_QUES_FEEDBACK_SUCCESS: "GET_QUES_FEEDBACK_SUCCESS",
    GET_QUES_FEEDBACK_ERROR: "GET_QUES_FEEDBACK_ERROR",
    SET_QUES_FEEDBACK_REQUEST: "SET_QUES_FEEDBACK_REQUEST",
    SET_QUES_FEEDBACK_SUCCESS: "SET_QUES_FEEDBACK_SUCCESS",
    SET_QUES_FEEDBACK_ERROR: "SET_QUES_FEEDBACK_ERROR",
    SET_QUES_FEEDBACK_RESET: "SET_QUES_FEEDBACK_RESET",
    SET_QUES_COUNT_RESET: "SET_QUES_COUNT_RESET",
    SET_QUES_COUNT_REQUEST: "SET_QUES_COUNT_REQUEST",
    SET_QUES_COUNT_SUCCESS: "SET_QUES_COUNT_SUCCESS",
    SET_QUES_COUNT_ERROR: "SET_QUES_COUNT_ERROR",
    GET_QUES_TUTOR_FEEDBACK_REQUEST: "GET_QUES_TUTOR_FEEDBACK_REQUEST",
    GET_QUES_TUTOR_FEEDBACK_SUCCESS: "GET_QUES_TUTOR_FEEDBACK_SUCCESS",
    GET_QUES_TUTOR_FEEDBACK_ERROR: "GET_QUES_TUTOR_FEEDBACK_ERROR",
    SET_QUES_TUTOR_FEEDBACK_REQUEST: "SET_QUES_TUTOR_FEEDBACK_REQUEST",
    SET_QUES_TUTOR_FEEDBACK_SUCCESS: "SET_QUES_TUTOR_FEEDBACK_SUCCESS",
    SET_QUES_TUTOR_FEEDBACK_ERROR: "SET_QUES_TUTOR_FEEDBACK_ERROR",
    SET_QUES_TUTOR_FEEDBACK_RESET: "SET_QUES_TUTOR_FEEDBACK_RESET",
    GET_TUTOR_FEEDBACK_ALL_REQUEST: "GET_TUTOR_FEEDBACK_ALL_REQUEST",
    GET_TUTOR_FEEDBACK_ALL_SUCCESS: "GET_TUTOR_FEEDBACK_ALL_SUCCESS",
    GET_TUTOR_FEEDBACK_ALL_ERROR: "GET_TUTOR_FEEDBACK_ALL_ERROR",
    GET_STUDENT_FEEDBACK_ALL_REQUEST: "GET_STUDENT_FEEDBACK_ALL_REQUEST",
    GET_STUDENT_FEEDBACK_ALL_SUCCESS: "GET_STUDENT_FEEDBACK_ALL_SUCCESS",
    GET_STUDENT_FEEDBACK_ALL_ERROR: "GET_STUDENT_FEEDBACK_ALL_ERROR",
    CREATE_TUTOR_FEEDBACK_BLOCK_RESET: "CREATE_TUTOR_FEEDBACK_BLOCK_RESET",
    CREATE_TUTOR_FEEDBACK_BLOCK_REQUEST: "CREATE_TUTOR_FEEDBACK_BLOCK_REQUEST",
    CREATE_TUTOR_FEEDBACK_BLOCK_SUCCESS: "CREATE_TUTOR_FEEDBACK_BLOCK_SUCCESS",
    CREATE_TUTOR_FEEDBACK_BLOCK_ERROR: "CREATE_TUTOR_FEEDBACK_BLOCK_ERROR",
    END_TUTOR_FEEDBACK_BLOCK_REQUEST: "END_TUTOR_FEEDBACK_BLOCK_REQUEST",
    END_TUTOR_FEEDBACK_BLOCK_SUCCESS: "END_TUTOR_FEEDBACK_BLOCK_SUCCESS",
    END_TUTOR_FEEDBACK_BLOCK_ERROR: "END_TUTOR_FEEDBACK_BLOCK_ERROR",
    GET_ALL_PLANS_REQUEST: "GET_ALL_PLANS_REQUEST",
    GET_ALL_PLANS_SUCCESS: "GET_ALL_PLANS_SUCCESS",
    GET_ALL_PLANS_ERROR: "GET_ALL_PLANS_ERROR",
    CREATE_PLAN_REQUEST: "CREATE_PLAN_REQUEST",
    CREATE_PLAN_RESET: "CREATE_PLAN_RESET",
    CREATE_PLAN_SUCCESS: "CREATE_PLAN_SUCCESS",
    CREATE_PLAN_ERROR: "CREATE_PLAN_ERROR",
    UPDATE_PLAN_RESET: "UPDATE_PLAN_RESET",
    UPDATE_PLAN_REQUEST: "UPDATE_PLAN_REQUEST",
    UPDATE_PLAN_SUCCESS: "UPDATE_PLAN_SUCCESS",
    UPDATE_PLAN_ERROR: "UPDATE_PLAN_ERROR",
    DELETE_PLAN_RESET: "DELETE_PLAN_RESET",
    DELETE_PLAN_REQUEST: "DELETE_PLAN_REQUEST",
    DELETE_PLAN_SUCCESS: "DELETE_PLAN_SUCCESS",
    DELETE_PLAN_ERROR: "DELETE_PLAN_ERROR",
    SELECT_PLAN_RESET: "SELECT_PLAN_RESET",
    SELECT_PLAN_REQUEST: "SELECT_PLAN_REQUEST",
    SELECT_PLAN_SUCCESS: "SELECT_PLAN_SUCCESS",
    SELECT_PLAN_ERROR: "SELECT_PLAN_ERROR",
    GET_ACTIVE_PLANS_RESET: "GET_ACTIVE_PLANS_RESET",
    GET_ACTIVE_PLANS_REQUEST: "GET_ACTIVE_PLANS_REQUEST",
    GET_ACTIVE_PLANS_SUCCESS: "GET_ACTIVE_PLANS_SUCCESS",
    GET_ACTIVE_PLANS_ERROR: "GET_ACTIVE_PLANS_ERROR",
    SEND_INVITES_RESET: "SEND_INVITES_RESET",
    SEND_INVITES_REQUEST: "SEND_INVITES_REQUEST",
    SEND_INVITES_SUCCESS: "SEND_INVITES_SUCCESS",
    SEND_INVITES_ERROR: "SEND_INVITES_ERROR",
};

export default types;